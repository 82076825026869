#qrcode {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 20px;
}
.jxzf_1 {
  font-size: 16px;
  margin-bottom: 10px;
}
.jxzf_2 {
  margin-bottom: 10px;
}
.jxzf_2 span {
  color: red;
  font-size: 16px;
  font-weight: 600;
}
.jxzf_3 {
  margin-bottom: 20px;
}
.jxzf_4 {
  text-align: center;
  margin-top: 20px;
  color: #8b8b8b;
}
.el-upload {
  height: 180px !important;
}
.upload-btn {
  height: 180px;
  margin: 20px 0;
}
.text_1 {
  font-size: 22px;
  font-weight: 600;
  color: red;
}
